<template>
  <div class="profile">
    <h3 @click="here()">Profile</h3>
  </div>
  <div class="profile_body">

    <div class="image_block">
      <div class="image">
        <div v-if="newImageFile || (cloneUser.image !== '' && cloneUser.image !== undefined && cloneUser.image !== null)" :style="{ backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url(${(newImageFile ? newImageFile : cloneUser.image)})` }"></div>
        <div v-else style="background-color: #2525251A; display: flex; justify-content: center; align-items: center; font-size: 32px;">{{ user.metadata?.name }}</div>
        <transition v-if="imageEditor">
          <imgx :src="profileImage" :imageEditor="true"
                @editedImageUploadCompleted="editedImageUploadComplete"
                @editedImageClosed="editedImageClose"></imgx>
        </transition>
      </div>
     <div class="button" @click.prevent="uploadImageFromDevice">Upload photo</div>
     <input type="file" id="profile_image_input" accept="image/*" style="display:none;" @change="sendImageToEditor">

      <div v-if="cloneUser.image !== '' && cloneUser.image !== undefined && cloneUser.image !== null" class="button secondary" @click="removeUserPhoto">Remove photo</div>
    </div>
    <div class="profile_part">
      <div class="first_name">
        <div>First name</div>
        <input type="text" v-model="cloneUser.name" :style="emptyName ? {borderColor: 'red'} : null">
			  <span v-if="emptyName">{{ errMsg }}</span>
      </div>
      <div class="last_name">
        <div>Last name</div>
        <input type="text" v-model="cloneUser.surname" :style="emptyLastName ? {borderColor: 'red'} : null">
			  <span v-if="emptyLastName">{{ errMsg }}</span>
      </div>
<!--      <div class="title">-->
<!--        <div>Phone</div>-->
<!--        <input type="text">-->
<!--      </div>-->
	    <div class="button" @click="saveUserMetadata">Confirm</div>
    </div>


    <div class="email_part">
      <div class="email">
        <p class="tileTitle">Email</p>
        <p>{{ user.email }}</p>
        <!-- <div class="button secondary" @click="editEmail()">Change email</div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, toRefs, defineEmits } from 'vue';
import { SuperTokensController as STC } from '../../services/SuperTokensController.js';
import { useStore } from 'vuex';
import DropZoneImage from '../../components/dropzones/cloudflare_image_profile.vue';
import imgx from '@/components/imageeditor/user_image.vue';

const store = useStore();
let user = ref(store.state.user);
let cloneUser = ref({ ...user.value.metadata });
let emptyName = ref(false);
let emptyLastName = ref(false);
let errMsg = ref('Field can not be empty');

let uploading = ref(false);
let currentUrl = ref('');
let profileImage = ref('');
let imageEditor = ref(false);
let newImageFile = ref(false);

// function here() {
//   console.log(store.state.user.metadata,'store.state.user')
// };

function uploadStarted(path) {
  uploading.value = true;
  // console.log("Image Path" + path);
  imageEditor.value = true;
  let url = '';
  url = path;
  url = url.replace('upload.','');
  url = url + "/profile";
  currentUrl.value = url;
  console.log(url, 'url')
}
async function sendImageToEditor(event) {
  console.log(event.target.value);
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      profileImage.value = e.target.result;
      imageEditor.value = true;
    };
    reader.readAsDataURL(file);
  }
  event.target.value = '';
}
function removeUserPhoto() {
  newImageFile.value = false;
  cloneUser.value.image = '';
}
function editedImageUploadComplete(newUrl, file) {
  console.log(newUrl);
  if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
    //editProfileImg.value = 'url(' + newUrl + ')';
    newImageFile.value = file;
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        newImageFile.value = e.target.result; // Устанавливаем атрибут src для тега img
      }
      reader.readAsDataURL(file); // Читаем содержимое файла как Data URL
    } else {
      newImageFile.value = false;
    }
    cloneUser.value.image = newUrl;
    profileImage.value = newUrl;
    imageEditor.value = false;
  } else {
    console.log('Image is not uploaded');
  }
}
function editedImageClose() {
  imageEditor.value = false;
}
function uploadImageFromDevice() {
  document.getElementById('profile_image_input').click();
}
// function editEmail() {
//   emit('change_email');
// }
async function saveUserMetadata() {
  store.commit('savingPage');
  user.value.metadata = { ...cloneUser.value };
  await STC.setCurrentUserMetadata(user.value.metadata);
  cloneUser.value = {};
  cloneUser.value = { ...user.value.metadata };
  store.commit('pageSaved');
}
</script>

<style lang="scss" scoped>
.profile{
  h3{
    height: 60px;
    line-height: 60px;
    margin: 0px;
    padding: 0 20px 0 20px;
    background-color: #f2f2f2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.image_block{
  display: flex;
  align-items: center;
  .image{
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    width: 116px;
    div{
      height: 92px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .button{
    width: fit-content;
    padding: 0 24px;
    margin-right: 15px;
  }
}
.profile_body{
  padding: 0 20px;
  .profile_part{
    border-top: 1px solid #bcbcbc;
    margin-top: 16px;
    padding-top: 10px;
    >div{
      display: flex;
      flex-wrap: wrap;
      >div{
        width: 116px;
      }
      label{
        display: block;
        width: 100%;
        text-align: end;
      }
    }
    .first_name,.last_name,.title{
      // height: 40px;
      margin-top: 15px;
      >div{
        display: flex;
        align-items: center;
      }
      input{
        height: 40px;
        line-height: 38px;
        box-sizing: border-box;
        outline: none;
        border: 1px solid #BCBCBC;
        border-radius: 8px;
        padding: 0 0 0 8px;
        flex-grow: 1;
        font-size: 1rem;
        color: #252525;
        font-family: 'Inter', sans-serif;
        max-width: 364px;
      }
			span{
				flex-grow: 0;
				flex-basis: 70%;
				padding-left: 116px;
				font-size: 0.75rem;
				color: red;
			}
    }
		.button{
			max-width: fit-content;
			padding: 0 24px;
			flex-grow: 0;
			margin-top: 16px;
		}
  }
  .email_part{
    border-top: 1px solid #bcbcbc;
    margin-top: 24px;
    padding-top: 20px;
    .email{
      position: relative;
      p{
        margin: 8px 0;
      }
      .button{
        width: fit-content;
        padding: 0 24px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
}
</style>